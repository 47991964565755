import React, { useEffect } from 'react';
import logo from '../assets/mascott-logo.png';

const FaviconComponent = () => {
  useEffect(() => {
    // Create a new link element for the favicon
    const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
    link.type = 'image/png';
    link.rel = 'icon';
    link.href = logo;

    // Add it to the head if it doesn't exist
    if (!document.querySelector("link[rel~='icon']")) {
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    // Update the title
    document.title = 'Project Scheduler';

    // Cleanup
    return () => {
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    };
  }, []);

  return null; // This component doesn't render anything
};

export default FaviconComponent;