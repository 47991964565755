import React from 'react';
import { 
  RouterProvider, 
  createBrowserRouter,
  createRoutesFromElements,
  Route 
} from 'react-router-dom';
import { AuthProvider } from './authContext';
import { BranchProvider } from './BranchContext';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Settings from './components/Settings';
import Registration from './components/Registration';
import ConfirmEmail from './components/ConfirmEmail';
import ManageUsers from './components/ManageUsers';
import ARReviewForm from './components/ARReviewForm';
import ARReviewSuccess from './components/ARReviewSuccess';
import ProtectedRoute from './components/ProtectedRoute';
import FaviconComponent from './components/FaviconComponent';
import './styles/App.css';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />
      <Route path="/" element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      } />
      <Route path="/dashboard" element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      } />
      <Route path="/settings" element={
        <ProtectedRoute>
          <Settings />
        </ProtectedRoute>
      } />
      <Route path="/manage-users" element={
        <ProtectedRoute>
          <ManageUsers />
        </ProtectedRoute>
      } />
      <Route path="/ar-review/:token" element={<ARReviewForm />} />
      <Route path="/ar-review/:token/success" element={<ARReviewSuccess />} />
    </Route>
  ),
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true
    }
  }
);

function App() {
  return (
    <AuthProvider>
      <BranchProvider>
        <FaviconComponent />
        <RouterProvider router={router} />
      </BranchProvider>
    </AuthProvider>
  );
}

export default App;